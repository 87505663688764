import React, { useEffect } from "react";

import { navigate } from "gatsby";

import { SessionType } from "@/autoGeneratedGlobalTypes";
import {
  getSession_getSession_client,
  getSession_getSession_expert,
} from "@/components/chat/graphql/__generated__/getSession";
import { VALID_SESSION_LENGTH_MIN } from "@/components/constants";
import { marketingFreeConsultationHandler } from "@/marketing/utils";
import { isBrowser } from "@/utils/env";
import Review from "@components/chat/review";

type ReviewPageProps = {
  location: {
    state: null | {
      receiver: getSession_getSession_client | getSession_getSession_expert;
      durationInMinutes: number;
      totalPrice: number;
      sessionID: number;
      sessionType: SessionType;
      isExpert: boolean;
    };
  };
};

const ReviewPage = ({ location }: ReviewPageProps) => {
  const {
    receiver,
    durationInMinutes = 0,
    totalPrice = 0,
    sessionID = 0,
    sessionType = SessionType.PHONE_CALL,
    isExpert = false,
  } = location.state || {};

  useEffect(() => {
    if (totalPrice === 0
      && !isExpert
      && durationInMinutes >= VALID_SESSION_LENGTH_MIN
    ) {
      marketingFreeConsultationHandler();
      navigate(
        `/appointment?expert_id=${receiver?.id}&session_type=${sessionType}`,
        {
          state: { redirectToPaymentFlag: true },
        },
      );
    }
  }, [totalPrice, durationInMinutes, isExpert, receiver?.id, sessionType]);

  if (!isBrowser()) {
    return null;
  }

  return (
    <Review
      durationInMinutes={durationInMinutes}
      totalPrice={totalPrice}
      receiver={receiver || null}
      sessionID={sessionID}
      sessionType={sessionType}
      isExpert={isExpert}
    />
  );
};

export default ReviewPage;
