import { useContext } from "react";

import { FormTypeEnum } from "@layout/modals/types";
import { setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import { setRedirectUrlSessionStorage } from "@/account/redirect";
import { SessionType } from "@/autoGeneratedGlobalTypes";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { marketingCallButtonClickHandler } from "@/marketing/utils";
import { isUserEligibleForImmediateCall } from "@/utils/commonUtils";

/**
 * Incapsulates call button click logic, e.g. redirects to correct session pages
 * @returns Function incapsulating call button click logic
 */
const useCallClick = (expertId: number, sessionType: SessionType, isTrialAllowed: boolean) => {
  const { email, isUserLoggedIn, freeMinutesCount } = useContext<UserContextType>(UserContext);
  const location = useLocation();

  const callClickHandler = () => {
    marketingCallButtonClickHandler();

    let redirectToCallUrl = `/?expert_id=${expertId}&session_type=${sessionType}&is_trial_allowed=${isTrialAllowed}`;

    if (isUserLoggedIn && isUserEligibleForImmediateCall(sessionType, freeMinutesCount, isTrialAllowed)) {
      if (sessionType === SessionType.PHONE_CALL) {
        redirectToCallUrl = `/appointment${redirectToCallUrl}`;
      } else {
        // todo for refactoring:
        // use GATSBY_ALLOW_FIRST_VIDEO_SESSION_WITHOUT_PAYMENT and GATSBY_ALLOW_FIRST_CHAT_SESSION_WITHOUT_PAYMENT
        // todo for phone calls: maybe just use useCreateVideoSession/useCreateCallSession
        // but take care of setRedirectURL redirects
        redirectToCallUrl = `/session${redirectToCallUrl}`;
      }
    } else {
      redirectToCallUrl = `/appointment${redirectToCallUrl}`;
    }

    if (isUserLoggedIn) {
      if (email) {
        navigate(redirectToCallUrl, {
          state: {
            sessionType,
          },
        });
      } else if (!email && freeMinutesCount && freeMinutesCount >= 7) {
        setAuthParamToURL(location, FormTypeEnum.QuestionnaireBeforeCall);
        setRedirectUrlSessionStorage(redirectToCallUrl);
      } else {
        navigate(redirectToCallUrl, {
          state: {
            sessionType,
          },
        });
      }
    } else {
      setAuthParamToURL(location, FormTypeEnum.LoginBeforeCall);
      setRedirectUrlSessionStorage(redirectToCallUrl);
    }
  };

  return { callClickHandler };
};

export default useCallClick;
