import React, {
  useCallback, useContext, useEffect, useState,
} from "react";

import { useMutation } from "@apollo/client";
import { Link, navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { getRedirectUrlSessionStorage, setRedirectUrlSessionStorage } from "@/account/redirect";
import { SessionType } from "@/autoGeneratedGlobalTypes";
import Confetti from "@/components/common/confetti";
import { CATALOG_PAGE_NAME } from "@/components/constants";
import GlobalContext from "@/contexts/Global/GlobalContext";
import useCallClick from "@/hooks/useCallClick";
import { marketingContinueConsultation } from "@/marketing/utils";
import { isBrowser } from "@/utils/env";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import { FeedbackSlider } from "@components/common/feedbackSlider";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";

import {
  setReviewAfterSession,
  setReviewAfterSessionVariables,
} from "../graphql/__generated__/setReviewAfterSession";
import { SET_REVIEW_MUTATION } from "../graphql/SET_REVIEW_MUTATION";

import { ReviewProps } from "./types";
import "./styles.scss";

const Review = (props: ReviewProps) => {
  const {
    durationInMinutes, totalPrice, receiver, sessionID, sessionType, isExpert,
  } = props;
  const { setIsAlertShown, setAlertStatus } = useContext(GlobalContext);

  // todo: check isTrialAllowed expert flag
  const { callClickHandler } = useCallClick(receiver?.id!, sessionType, false);

  const [isReviewSectionOpen, setIsReviewSectionOpen] = useState<boolean>(false);
  const [showAnimationWithConfetti, setShowAnimationWithConfetti] = useState(false);

  const [clickedStar, setClickedStar] = useState<number | null>();
  const [text, setText] = useState<string>("");

  const [
    setReviewMutation,
    { loading: setReviewLoading },
  ] = useMutation<setReviewAfterSession, setReviewAfterSessionVariables>(SET_REVIEW_MUTATION);

  const handleStarClick = useCallback((clickedRating: number) => {
    setIsReviewSectionOpen(true);
    setClickedStar(clickedRating);
    setReviewMutation({
      variables: {
        rating: clickedRating,
        sessionID,
      },
    });
    // todo: check if deps are ok
  }, [sessionID, setReviewMutation]);

  // Turning off the alert
  useEffect(() => {
    setIsAlertShown(false);

    return () => {
      setIsAlertShown(true);
    };
  }, [setIsAlertShown]);

  useEffect(
    () =>
      () => {
        window.location.reload();
      },
    [],
  );

  useEffect(() => {
    if (!sessionID || !durationInMinutes || isExpert === null) {
      if (sessionType === SessionType.PHONE_CALL) {
        return;
      }

      if (getRedirectUrlSessionStorage()) {
        const redirectUrl = getRedirectUrlSessionStorage() || "";
        navigate(redirectUrl);
        setRedirectUrlSessionStorage("");
      } else {
        navigate(`/${CATALOG_PAGE_NAME}`);
      }
    }
    // todo: check if adding sessionType in deps is ok
  }, [sessionID, durationInMinutes, isExpert, sessionType]);

  if (!isBrowser()) {
    return null;
  }

  const handleReviewSubmit = () => {
    if (!clickedStar) {
      return;
    }
    // todo: move alert to this component
    setAlertStatus("review_ended");
    setIsAlertShown(true);
    setShowAnimationWithConfetti(true);
    setReviewMutation({
      variables: {
        text,
        rating: clickedStar,
        sessionID,
      },
    });
  };

  const handleStartSessionClick = () => {
    if (receiver?.id) {
      marketingContinueConsultation();
      callClickHandler();
    }
  };

  return (
    <>
      {showAnimationWithConfetti && <Confetti />}
      <div className="review">
        <Helmet>
          <body className="footer--short header--short" />
        </Helmet>
        <div className="review__session-block">
          <Link to={`/${CATALOG_PAGE_NAME}`}>
            <Icon className="review__close" type={IconTypeEnum.Close} size={IconSizeEnum.Size32} />
          </Link>
          <div className="review__icon">
            <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size28} />
          </div>
          <h3 className="review__title">
            Консультация
            <br />
            завершена
          </h3>
          <div className="review__info">
            <div className="review__info-item">
              <Icon type={IconTypeEnum.Time} size={IconSizeEnum.Size20} />
              {durationInMinutes}
              {" "}
              мин
            </div>
            <div className="review__info-item">
              {isExpert ? (
                <>
                  ID
                  {" "}
                  {sessionID}
                </>
              ) : (
                <>
                  <Icon type={IconTypeEnum.Card} size={IconSizeEnum.Size20} />
                  {totalPrice}
                  {" "}
                  ₽
                </>
              )}
            </div>
          </div>
          {!isExpert && (
          <Button
            text="Продолжить консультацию"
            className="review__continue-call"
            color={ButtonColorEnum.Light}
            onClick={handleStartSessionClick}
          />
          )}
        </div>
        <div className="review__review-block">
          <div className="review__panel">
            {!isExpert && receiver && (
            <>
              <h2>Оцените работу эксперта</h2>
              <div className="review__hint">
                Ваша обратная связь поможет нам и другим пользователям
              </div>
              <div className="review__stars">
                <FeedbackSlider
                  rating={clickedStar}
                  onChange={handleStarClick}
                  disabled={setReviewLoading}
                />
              </div>
              <div className={isReviewSectionOpen ? "review__review opened" : "review__review"}>
                <div className="review__subtext">Спасибо за вашу оценку</div>
                <div className="review__label">Ваш комментарий</div>
                <div className="review__comment">
                  <textarea
                    placeholder="Что вам понравилось или не понравилось?"
                    value={text}
                    onChange={(e) =>
                      setText(e.target.value)}
                  />
                </div>
                <div className="review__button-container">
                  <Button
                    text="Оставить отзыв"
                    color={ButtonColorEnum.Dark}
                    size={ButtonSizeEnum.Middle}
                    onClick={handleReviewSubmit}
                    isLoading={setReviewLoading}
                  />
                </div>
              </div>
            </>
            )}
            {isExpert && (
            <>
              <h2>Спасибо за вашу работу</h2>
              <div className="review__button-container">
                <Link to={`/${CATALOG_PAGE_NAME}`}>
                  <Button
                    text="Закрыть"
                    color={ButtonColorEnum.Light}
                    size={ButtonSizeEnum.Large}
                  />
                </Link>
              </div>
            </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
