import * as React from "react";

import _uniqueId from "lodash/uniqueId";

import { Icon, IconSizeEnum, IconTypeEnum } from "../icon";

import { FeedbackSliderProps } from "./types";
import "./styles.scss";

export const FeedbackSlider = (props: FeedbackSliderProps) => {
  const {
    rating = null, onChange, disabled = false, iconSize = IconSizeEnum.Size48, ...rest
  } = props;

  const uniqueId = _uniqueId();

  return (
    <div className={disabled ? "feedback disabled" : "feedback"} {...rest}>
      <input
        type="radio"
        name={`${uniqueId}-rating`}
        id={`${uniqueId}-rating-5`}
        checked={(rating ?? 0) >= 5}
        onChange={() =>
          onChange && onChange(5)}
        disabled={disabled}
      />
      <label htmlFor={`${uniqueId}-rating-5`}>
        <Icon type={IconTypeEnum.Fav} size={iconSize} />
      </label>
      <input
        type="radio"
        name={`${uniqueId}-rating`}
        id={`${uniqueId}-rating-4`}
        checked={rating === 4}
        onChange={() =>
          onChange && onChange(4)}
        disabled={disabled}
      />
      <label htmlFor={`${uniqueId}-rating-4`}>
        <Icon type={IconTypeEnum.Fav} size={iconSize} />
      </label>
      <input
        type="radio"
        name={`${uniqueId}-rating`}
        id={`${uniqueId}-rating-3`}
        checked={rating === 3}
        onChange={() =>
          onChange && onChange(3)}
        disabled={disabled}
      />
      <label htmlFor={`${uniqueId}-rating-3`}>
        <Icon type={IconTypeEnum.Fav} size={iconSize} />
      </label>
      <input
        type="radio"
        name={`${uniqueId}-rating`}
        id={`${uniqueId}-rating-2`}
        checked={rating === 2}
        onChange={() =>
          onChange && onChange(2)}
        disabled={disabled}
      />
      <label htmlFor={`${uniqueId}-rating-2`}>
        <Icon type={IconTypeEnum.Fav} size={iconSize} />
      </label>
      <input
        type="radio"
        name={`${uniqueId}-rating`}
        id={`${uniqueId}-rating-1`}
        checked={rating === 1}
        onChange={() =>
          onChange && onChange(1)}
        disabled={disabled}
      />
      <label htmlFor={`${uniqueId}-rating-1`}>
        <Icon type={IconTypeEnum.Fav} size={iconSize} />
      </label>
    </div>
  );
};

export { IconSizeEnum };
